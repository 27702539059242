import { airdoor, MunicipalityGroupData } from "@airdoor/master-data";
import ShikugunGroup from "../region/ShikugunGroupType";
import { makeMunicipality, Municipality } from "./Municipality";
import { generatePrefecture } from "./Prefecture";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";

type MunicipalityGroupBase = {
  slug: string;
  jisX_0401: string;
  prefectureSlug: string;
  displayName: string;
  crumbName: string;
  queryKey: "sg";
  jisX_0401_0402Codes: string[];
  municipalities: Municipality[];
};

export type QueryableMunicipalityGroup = MunicipalityGroupBase & {
  id: number;
  query: { sg: string };
  url: string;
};
export type NotQueryableMunicipalityGroup = MunicipalityGroupBase & {
  id: undefined;
  query: undefined;
  url: undefined;
};

export type MunicipalityGroup = QueryableMunicipalityGroup | NotQueryableMunicipalityGroup;

export const generateMunicipalityGroup = (
  shikugunGroup: ShikugunGroup,
): { displayName: string; url: string; prefecture: ReturnType<typeof generatePrefecture> } => {
  return {
    displayName: shikugunGroup.short_display_name ?? shikugunGroup.display_name,
    url: "/list?sg=d-" + shikugunGroup.id,
    prefecture: generatePrefecture(shikugunGroup.todofuken),
  };
};

export function getMunicipalityGroup(where: { id: number }): QueryableMunicipalityGroup;
export function getMunicipalityGroup(where: { slug: string }): MunicipalityGroup;
export function getMunicipalityGroup(where: { slug: string } | { id: number }): MunicipalityGroup {
  const municipalityGroup =
    "slug" in where
      ? airdoor.municipalityGroups.get(where.slug)
      : airdoor.municipalityGroups.getById(where.id);
  return makeMunicipalityGroup(municipalityGroup);
}
export function getFulfilledGroups(jisCodes: string[]): MunicipalityGroup[] {
  if (jisCodes.length < 1) return [];

  const groups = airdoor.municipalityGroups.all();
  return groups
    .filter((group) => group.municipalityJisCodes.every((jisCode) => jisCodes.includes(jisCode)))
    .map((g) => makeMunicipalityGroup(g));
}

export const getAllMunicipalityGroup = () => {
  return airdoor.municipalityGroups.all().map((mg) => makeMunicipalityGroup(mg));
};

export const makeMunicipalityGroup = (data: MunicipalityGroupData): MunicipalityGroup => {
  const base = {
    slug: data.slug,
    jisX_0401: data.prefecture.jisX_0401,
    prefectureSlug: data.prefectureSlug,
    displayName: data.name,
    crumbName: data.shortName || data.name,
    queryKey: QUERY_KEY.SHIKUGUN_GROUP,
    jisX_0401_0402Codes: data.municipalityJisCodes,
    get municipalities() {
      return data.municipalities.map((m) => makeMunicipality(m));
    },
  };

  if (data.id) {
    const legacyCode: string | undefined = convertLegacyCode(data.slug);
    if (!legacyCode) throw new TypeError("市区群グループの初期化に失敗しました。");

    return {
      ...base,
      id: data.id,
      get query() {
        return { [QUERY_KEY.SHIKUGUN_GROUP]: `d-${legacyCode}` };
      },
      get url() {
        return `/list?${new URLSearchParams(this.query).toString()}`;
      },
    };
  }

  return {
    ...base,
    id: undefined,
    query: undefined,
    url: undefined,
  };
};

const convertLegacyCode = (slug: string): string | undefined => {
  return {
    "23ku": "131000",
    yokohama: "141003",
    kawasaki: "141305",
    sagamihara: "141500",
  }[slug];
};
