import { airdoor, RailwayData } from "@airdoor/master-data";
import { getAllRailwayCompanies, makeRailwayCompany, RailwayCompany } from "./RailwayCompany";
import { makeStation, Station } from "./Station";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";

export type Railway = {
  id: number;
  displayName: string;
  url: string | undefined;
  colorCode: string;
  majorStations: string[] | undefined;
  prefectureSlugs: string[];
  legacyCode: string | undefined;
  queryKey: "li";
  query: { li: string } | undefined;
  displayOrder: number;
  stationIds: number[];
  stations: Station[];
  railwayCompany: RailwayCompany;
};
export type IndexableRailway = Railway & { query: { [key: string]: string }; url: string };

export const getRailway = (railwayId: number): Railway | undefined => {
  try {
    const railway = airdoor.railways.get(railwayId);
    return makeRailway(railway);
  } catch (e) {
    // 登録のない沿線の可能性があるので
  }
};
export const getRailwayStrict = (railwayId: number): Railway => {
  const railway = airdoor.railways.get(railwayId);
  return makeRailway(railway);
};
export const getRailwayByLegacyCode = (legacyCode: string): Railway => {
  const railway = airdoor.legacyRailways.get(legacyCode);

  return makeRailway(railway, legacyCode);
};

export const getAllRailways = (prefectureSlugs?: string[]): Railway[] => {
  return getAllRailwayCompanies(prefectureSlugs).flatMap((rc) => rc.railways);
};

/**
 * @param stationIds
 * @param prefectureSlug 指定すると特定の沿線ないの駅のみに絞り込み可能
 * @returns
 */
export const getFulfilledRailways = (stationIds: number[], jisX_0401?: string): Railway[] => {
  if (stationIds.length < 1) return [];

  const railways = airdoor.prefectures
    .all()
    .filter((p) => !jisX_0401 || p.jisX_0401 === jisX_0401)
    .flatMap((p) => p.railwayCompanies.flatMap((c) => c.railways));

  // 都道府県指定のある場合は、該当都道府県に含まれる駅のみで判定する
  return railways
    .filter((r) => {
      const stations = r.stations.filter(
        (s) => !jisX_0401 || s.jisX_0401_0402.startsWith(jisX_0401),
      );
      return stations.length > 0 && stations.every((s) => stationIds.includes(s.id));
    })
    .map((r) => makeRailway(r));
};

export const makeRailway = (data: RailwayData, legacyCode?: string) => {
  const legacyRailwayCode = legacyCode || airdoor.legacyRailways.getLegacyCode(data.id);

  return {
    id: data.id,
    displayName: data.name,
    colorCode: data.colorCode,
    majorStations:
      legacyRailwayCode in MAJOR_STATIONS_LEGACY_CODE_MAPS
        ? MAJOR_STATIONS_LEGACY_CODE_MAPS[legacyRailwayCode]
        : undefined,
    prefectureSlugs: data.prefectureSlugs,
    legacyCode: legacyRailwayCode,
    displayOrder: data.displayOrder,
    stationIds: data.stationIds,
    queryKey: QUERY_KEY.LINE,
    get query() {
      if (!this.legacyCode) return;
      return { [this.queryKey]: `d-${this.legacyCode}` };
    },
    get url() {
      if (!this.legacyCode) return;
      return `/condition/route/${this.legacyCode}`;
    },
    get stations(): Station[] {
      return data.stations.map((s) => makeStation(s));
    },
    get railwayCompany() {
      return makeRailwayCompany(data.railwayCompany);
    },
  };
};

// TODO: master-dataに含める
const MAJOR_STATIONS_LEGACY_CODE_MAPS: { [key: string]: string[] } = {
  "89": ["東京", "新橋", "品川"],
  "91": ["東京", "渋谷", "新宿", "上野", "神田"],
  "93": ["赤羽", "上野", "東京", "新橋", "蒲田"],
  "94": ["東京", "新橋", "品川", "西大井"],
  "95": ["西大井", "渋谷", "新宿", "池袋", "浮間舟渡"],
  "99": ["矢野口", "稲城長沼", "府中本町", "分倍河原", "立川"],
  "100": ["鶴見", "国道", "鶴見小野", "浜川崎", "海芝浦"],
  "103": ["府中本町", "北府中", "西国分寺", "新小平", "新秋津"],
  "105": ["八王子", "片倉", "相原", "町田", "成瀬"],
  "107": ["茅ヶ崎", "北茅ヶ崎", "香川", "厚木", "橋本"],
  "110": ["東京", "飯田橋", "市ヶ谷", "新宿", "高尾"],
  "114": ["東京", "飯田橋", "市ヶ谷", "新宿", "高尾"],
  "115": ["立川", "西立川", "東中神", "拝島", "奥多摩"],
  "116": ["拝島", "熊川", "東秋留", "秋川", "武蔵五日市"],
  "117": ["八王子", "北八王子", "小宮", "拝島", "箱根ヶ崎"],
  "129": ["品川", "新橋", "東京", "上野", "金町"],
  "137": ["東京", "上野", "尾久", "赤羽"],
  "194": ["小岩", "飯田橋", "市ヶ谷", "新宿", "三鷹"],
  "196": ["東京", "新日本橋", "馬喰町", "錦糸町", "新小岩"],
  "199": ["東京", "八丁堀", "越中嶋", "新木場", "葛西臨海公園"],
  "212": ["駿河小山", "足柄", "御殿場", "裾野", "沼津"],
  "429": ["池袋", "椎名町", "南長崎", "練馬", "秋津"],
  "434": ["小竹向原", "新桜台", "練馬"],
  "435": ["練馬", "豊島園"],
  "437": ["西武新宿", "高田馬場", "中井", "小平", "東村山"],
  "440": ["小平", "萩山", "小川", "玉川上水", "拝島"],
  "442": ["国分寺", "一橋学園", "萩山", "武蔵大和", "多摩湖"],
  "443": ["国分寺", "恋ヶ窪", "鷹の台", "小川", "東村山"],
  "444": ["東村山", "西武園"],
  "446": ["武蔵境", "新小金井", "多磨", "白糸台", "是政"],
  "576": ["浅草", "上野", "神田", "新橋", "渋谷"],
  "577": ["池袋", "四ツ谷", "新宿", "方南町"],
  "579": ["北千住", "上野", "秋葉原", "恵比寿", "中目黒"],
  "580": ["中野", "高田馬場", "飯田橋", "大手町", "葛西"],
  "582": ["北綾瀬", "北千住", "西日暮里", "大手町", "代々木上原"],
  "583": ["地下鉄成増", "池袋", "飯田橋", "市ヶ谷", "新木場"],
  "584": ["渋谷", "青山一丁目", "大手町", "錦糸町", "押上"],
  "585": ["赤羽岩淵", "飯田橋", "市ヶ谷", "四ツ谷", "目黒"],
  "586": ["西馬込", "五反田", "新橋", "浅草", "押上"],
  "587": ["目黒", "日比谷", "大手町", "水道橋", "西高島平"],
  "588": ["新宿", "新宿三丁目", "市ヶ谷", "九段下", "篠崎"],
  "589": ["新宿西口", "飯田橋", "代々木", "新宿", "光が丘"],
  "590": ["三ノ輪橋", "荒川区役所前", "荒川二丁目", "熊野前", "早稲田"],
  "604": ["あざみ野", "新横浜", "横浜", "戸塚", "湘南台"],
  "629": ["小田原", "箱根板橋", "風祭", "入生田", "強羅"],
  "659": ["新杉田", "南部市場", "鳥浜", "並木北", "金沢八景"],
  "715": ["京成高砂", "新柴又"],
  "720": ["浜松町", "天王洲アイル", "天空橋", "羽田空港第1ターミナル", "羽田空港第2ターミナル"],
  "721": ["大船", "富士見町", "湘南町屋", "湘南深沢", "湘南江の島"],
  "722": ["鎌倉", "和田塚", "由比ヶ浜", "長谷", "藤沢"],
  "723": ["小田原", "緑町", "井細田", "五百羅漢", "大雄山"],
  "724": ["三島", "三島広小路", "三島田町", "三島二日町", "修繕寺"],
  "830": ["浅草", "押上", "曳舟", "北千住", "竹ノ塚"],
  "843": ["曳舟", "小村井", "東あずま", "亀戸水神", "亀戸"],
  "844": ["西新井", "大師前"],
  "848": ["池袋", "北池袋", "下板橋", "大山", "成増"],
  "855": ["京成上野", "日暮里", "青舐", "京成高砂", "江戸川"],
  "858": ["押上", "京成曳舟", "八広", "青砥", "京成高砂"],
  "860": ["京成高砂", "柴又", "京成金町"],
  "863": ["新宿", "明大前", "分倍河原", "高幡不動", "京成八王子"],
  "864": ["北野", "京王片倉", "山田", "高尾", "高尾山口"],
  "865": ["調布", "京王多摩川", "京王よみうりランド", "稲城", "多摩境"],
  "869": ["東府中", "府中競馬正門前"],
  "870": ["高畑不動", "多摩動物公園"],
  "871": ["渋谷", "神泉", "下北沢", "明大前", "吉祥寺"],
  "873": ["新宿", "南新宿", "代々木上原", "下北沢", "町田"],
  "877": ["相模大野", "中央林間", "湘南台", "藤沢", "片瀬江ノ島"],
  "879": ["小田急永山", "小田急多摩センター", "唐木田"],
  "882": ["渋谷", "中目黒", "自由が丘", "田園調布", "多摩川"],
  "885": ["渋谷", "池尻大橋", "三軒茶屋", "二子玉川", "南町田グランベリーパーク"],
  "889": ["目黒", "不動前", "大岡山", "田園調布", "多摩川"],
  "890": ["大井町", "中延", "大岡山", "自由ヶ丘", "二子玉川"],
  "891": ["五反田", "大崎広小路", "戸越銀座", "旗の台", "蒲田"],
  "892": ["長津田", "恩田", "こどもの国"],
  "893": ["三軒茶屋", "西太子堂", "若林", "松陰神社前", "下高井戸"],
  "894": ["泉岳寺", "品川", "北品川", "京急蒲田", "六郷土手"],
  "895": ["堀ノ内", "新大津", "北久里浜", "京急久里浜", "三崎口"],
  "896": ["金澤八景", "六浦", "神武寺", "逗子", "葉山"],
  "900": ["京急蒲田", "糀谷", "大鳥居", "天空橋", "羽田空港第1・第2ターミナル"],
  "901": ["京急川崎", "港町", "鈴木町", "川崎大師", "小島新田"],
  "902": ["二俣川", "南万騎が原", "緑園都市", "弥生台", "湘南台"],
  "903": ["横浜", "西谷", "二俣川", "大和", "海老名"],
  "1000": ["多摩センター", "松が谷", "高幡不動", "玉川上水", "上北台"],
  "1001": ["新橋", "汐留", "竹芝", "日の出", "豊洲"],
  "1002": ["新木場", "東雲", "天王洲アイル", "大井町", "大崎"],
  "1013": ["多摩川", "沼部", "鵜の木", "新丸子", "蒲田"],
  "2013": ["横浜", "新高島", "みなとみらい", "馬車道", "元町・中華街"],
  "2021": ["秋葉原", "浅草", "南千住", "北千住", "六町"],
  "2029": ["日暮里", "西日暮里", "赤土小学校前", "熊野前", "見沼代親水公園"],
  "2030": ["中山", "川和町", "センター南", "センター北", "日吉"],
  "2032": ["地下鉄成増", "小竹向原", "池袋", "新宿三丁目", "渋谷"],
  "2035": ["赤羽", "池袋", "新宿", "渋谷", "西大井"],
  "2036": ["赤羽", "池袋", "新宿", "渋谷", "大崎"],
  "2038": ["京成上野", "日暮里", "青砥", "京成高砂"],
  "2047": ["新横浜", "羽沢横浜国大", "西谷"],
};
