import { airdoor, PrefectureData } from "@airdoor/master-data";
import _ from "lodash";
import Todofuken from "../region/TodofukenType";
import { makeMunicipality, Municipality } from "./Municipality";
import { makeRailwayCompany, RailwayCompany } from "./RailwayCompany";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";

export type Prefecture = {
  slug: string;
  jisX_0401: string;
  displayName: string;
  queryKey: "to";
  query: { to: string };
  url: string;
  railwayCompanies: RailwayCompany[];
  municipalities: Municipality[];
};

export const generatePrefecture = (
  todofuken: Todofuken,
): {
  displayName: string;
  url: string;
} => {
  return {
    displayName: todofuken.display_name,
    url: "/list?to=d-" + todofuken.id,
  };
};

export function getPrefecture(where: { jisX_0401: string }): Prefecture;
export function getPrefecture(where: { slug: string }): Prefecture;
export function getPrefecture(where: { jisX_0401: string } | { slug: string }): Prefecture {
  const pref =
    "slug" in where
      ? airdoor.prefectures.get(where.slug)
      : airdoor.prefectures.getByJisCode(where.jisX_0401);

  return makePrefecture(pref);
}
export function getFulfilledPrefectures(groupSlugs: string[]): Prefecture[] {
  if (groupSlugs.length < 1) return [];

  const prefectures = airdoor.prefectures.all();
  return prefectures
    .filter((pref) => pref.municipalityGroupSlugs.every((slug) => groupSlugs.includes(slug)))
    .map((p) => makePrefecture(p));
}

export const getAllPrefectures = () => {
  return airdoor.prefectures.all().map((p) => makePrefecture(p));
};

export const makePrefecture = (data: PrefectureData): Prefecture => {
  return {
    jisX_0401: data.jisX_0401,
    slug: data.slug,
    displayName: data.name,
    queryKey: QUERY_KEY.TODOFUKEN,
    get query() {
      return { [this.queryKey]: `d-${data.jisX_0401}` };
    },
    get url() {
      return `/list?${new URLSearchParams(this.query).toString()}`;
    },
    get railwayCompanies() {
      return _.sortBy(data.railwayCompanies, "displayOrder").map((rc) => makeRailwayCompany(rc));
    },
    get municipalities() {
      return _.sortBy(data.municipalityGroups, "displayOrder")
        .flatMap((mg) => mg.municipalities)
        .map((m) => makeMunicipality(m));
    },
  };
};
